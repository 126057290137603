import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))

const SiteInfo = ({ siteInfo }) => {
    const type = siteInfo.infoStripType

    return (
        <div className="c-site-info">
            <div className="container-lg">
                <div className="c-site-info__inner">
                    {type === 'xmas' ? (
                        <>
                            <Image
                                data={siteInfo.infoStripXmasIcon}
                                className={'c-site-info__icon'}
                            />
                            <div>{parse(siteInfo.infoStripXmasContent)}</div>
                        </>
                    ) : type === 'easter' ? (
                        <>
                            <Image
                                data={siteInfo.infoStripEasterIcon}
                                className={'c-site-info__icon'}
                            />
                            <div>{parse(siteInfo.infoStripEasterContent)}</div>
                        </>
                    ) : type === 'bhol' ? (
                        <>
                            <Image
                                data={siteInfo.infoStripBholIcon}
                                className={'c-site-info__icon'}
                            />
                            <div>{parse(siteInfo.infoStripBholContent)}</div>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

SiteInfo.propTypes = {
    /**
     * Site info object from global options
     */
    siteInfo: PropTypes.shape({
        infoStripType: PropTypes.oneOf(['xmas', 'easter', 'bhol']).isRequired,
        infoStripXmasIcon: PropTypes.object,
        infoStripXmasContent: PropTypes.string,
        infoStripEasterIcon: PropTypes.object,
        infoStripEasterContent: PropTypes.string,
        infoStripBholIcon: PropTypes.object,
        infoStripBholContent: PropTypes.string
    })
}

SiteInfo.defaultProps = {
    siteInfo: {
        infoStripType: 'xmas',
        infoStripXmasContent: '<p>This is some site info</p>'
    }
}

export default SiteInfo
